import styled from "styled-components"
import { colors } from "../../const/colors"
import { Section } from "../HomePage/styles"

export const TheBerryVideoSection = styled(Section)`
  background:${colors.lightBg}
`

export const VideoBlock = styled.div`
  width:100%;
  max-width:800px;
  margin:50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  video {
    width:100%;
  }
  .slide {
    background:none !important;
  }
    .carousel.carousel-slider {
        overflow: visible;
        width:90% !important;
        margin:auto;
      }
      .carousel .control-dots .dot {
        background: ${colors.greenColor};
        box-shadow: none;
        border: 1px solid ${colors.grayColor};
      }
      .carousel.carousel-slider .control-arrow:hover {
        background:none;
      }
      .carousel .control-next.control-arrow:before {
        border-left: 20px solid ${colors.grayColor};
      }
      .carousel .control-prev.control-arrow:before {
        border-right: 20px solid ${colors.grayColor};
      }
      .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
        border-top: 20px solid #f7f7f7;
        border-bottom: 20px solid #f7f7f7;
      }
      .carousel.carousel-slider .control {
        &-arrow {
          color:${colors.grayColor};
        }
        &-next {
          left:100%;
        }
        &-prev {
          right:100%;
          left:initial;
        }
      }
`